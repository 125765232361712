import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image"
import {Section, SectionPageTitle, Container } from "../components/Section"
import {SearchIcon, CloseIcon } from "../components/Icons"
import { Nodata,  NodataContainer, NodataDesc, NodataMedia } from "../components/Nodata"
import { Link, graphql } from "gatsby"
import { useFlexSearch } from 'react-use-flexsearch'

import { Formik, Form, Field } from 'formik'

const Search = styled.div`
    margin-bottom:30px;
`
const SearchHeading = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    flex-direction:column;
    margin-bottom:20px;
    @media(min-width:768px){
        flex-direction:row;
        margin-bottom:30px;
    }
`
const SearchBody = styled.div`
    margin:0;
`
const SectionPageTitles = styled(SectionPageTitle)`  
    color:#999;
    > strong{
      color:#000;
    }
`
const Grid = styled.div`
    margin:0;
    `
const GridItem = styled.div`
  margin-bottom: 20px;
    + div{
        margin-top:20px;
    }
`
const CardIcon = styled.div`
    background-color:#fff;
    border-radius:20px;
    box-shadow:0 0 99px rgba(0,0,0,0.15);
    padding:30px;
    @media(min-width:1200px){
        padding:40px;
    }
    @media(min-width:1600px){
        padding:50px;
    }
`
const CardIconBody = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin:0 -15px;
`
const CardIconContent = styled.div`
    position:relative;
    width:100%;
    padding:0 15px;
    
    h2{
        font-family: 'Barlow', sans-serif;
        color:#1383C1;
        a{
            text-decoration:underline;            
            &:hover, &:focus{
                text-decoration:none;
            }
        }
    }
    p{
        font-weight:500;
        margin-bottom:5px;
        a{
            
            display: inline-block;
            text-decoration:underline;            
            &:hover, &:focus{
                text-decoration:none;
            }
        }
    }
    .more{
        transform: rotate(-45deg);
        transition:transform 0.5s ease;
        svg{
            fill:#1383C1;
        }
        &:hover, &:focus{
            transform: rotate(0deg);
            svg{
                fill:#E08932;
            }
        }
    }
    a {
        font-size: 16px;
        font-weight: bold;
    }
`

const SearchForm = styled.div`
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
    width: 100%;
    padding: 0 20px;
    margin-bottom:10px;
    @media(min-width:768px){
        max-width: 620px;
        padding: 0 30px;
    }
    .icon > svg{
        width:20px;
        @media(min-width:768px){
        width:24px;
        }
    }
    input {
      width: 100%;
      border: none;
      font-size: 20px;
    }
`
const NodataDescs = styled(NodataDesc)`
    position:absolute;
    top: 18%;
    left:50%;
    width:100%;
    transform:translateX(-50%);
    color:#1383C1;    
    font-weight:700;
    font-size: 20px;
    line-height: 20px;
    @media (min-width: 1200px) {
        font-size: 25px;
        line-height: 25px;
    }
    @media (min-width: 1600px) {
        font-size: 30px;
        line-height: 30px;
    }
`



const SearchResultPage = ({data, location}) => {
  const [query, setQuery] = useState(location?.state?.searchKey)
  const index = data.localSearchPages.index
  const store = data.localSearchPages.store

  const results = useFlexSearch(query, index, store)

  return(
    <Layout location={location}>
      <Section pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8" className="section-result">
        <Container maxWidth="1370px">
            <Search>
                <SearchHeading>
                    <SectionPageTitles className="h5">Results for <strong>“{query}”</strong></SectionPageTitles>
                    <SearchForm className='search-form'>
                        <div className="input-icon right">
                            <Formik
                              initialValues={{ query: '' }}
                              onSubmit={(values, { setSubmitting }) => {
                                setQuery(values.query)
                                setSubmitting(false)
                              }}
                            >
                              <Form>
                                <Field name="query" placeholder="Search ..." required />
                              </Form>
                            </Formik>
                            <span className="icon"><SearchIcon /></span>
                            <span className="icon" style={{display:"none"}}><CloseIcon  /></span>
                        </div>
                    </SearchForm>
                </SearchHeading>
                <SearchBody>
                  {
                    results?.length > 0 ? (
                      <Grid className="card-grid">
                      {results.map(result => (
                        <GridItem className="card-grid-item">
                            <CardIcon className="card">
                                <CardIconBody className="card-body">
                                    <CardIconContent className="card-content">
                                        <h2 className="h6">{result.title}</h2>
                                        <p>{result.body}</p>
                                        <Link to={result.path}>View Detail</Link>
                                    </CardIconContent>
                                </CardIconBody>
                            </CardIcon>
                        </GridItem>
                      ))}
                      </Grid>
                    ) : (
                      <Nodata>
                        <NodataContainer maxWidth="1375px">
                          <NodataMedia><StaticImage src="../images/search-bg.png" alt="search-bg" /></NodataMedia>
                          <NodataDescs>We couldn’t find anything?</NodataDescs>
                        </NodataContainer>
                      </Nodata>
                    )
                  }
                </SearchBody>
            </Search>
        </Container>
      </Section>
    </Layout>
  )

}

export default SearchResultPage

export const pageQuery = graphql`
  query SearchResultPageQuery{
    localSearchPages {
      index
      store
    }
  }
`
